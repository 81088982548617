export const environment = {
  npwp:"024562969402000",
  namaPerusahaan:"Arab Wings Express",
  namaProvider:"PT Mitra Inovasi Unggul",
  //dev
  // uiBarangKirimanDraftApiUrl : "https://devserver2-ui-barangkiriman-draft-api-617991738064.asia-southeast2.run.app",
  // uiBarangKirimanBarangApiUrl : "https://devserver2-ui-barangkiriman-barang-api-617991738064.asia-southeast2.run.app",
  // uiBarangKirimanBillingApiUrl : "https://devserver2-ui-barangkiriman-billing-api-617991738064.asia-southeast2.run.app",
  //prod
  uiBarangKirimanDraftApiUrl : "https://prodserver1-ui-barangkiriman-draft-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanBarangApiUrl : "https://prodserver1-ui-barangkiriman-barang-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanBillingApiUrl : "https://prodserver1-ui-barangkiriman-billing-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanReportApiUrl : "https://prodserver1-ui-barangkiriman-report-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanUserApiUrl : "https://prodserver2-ui-barangkiriman-user-api-786567254893.asia-southeast2.run.app",

  firebaseConfig : {
    apiKey: "AIzaSyCS52GGOJBwNQIEFmjKsR2oHwqWpQd61hw",
    authDomain: "pibjk-v1-prod.firebaseapp.com",
    projectId: "pibjk-v1-prod",
    storageBucket: "pibjk-v1-prod.appspot.com",
    messagingSenderId: "786567254893",
    appId: "1:786567254893:web:8a0b22cab5319df766c064",
    measurementId: "G-FK53MBM6WZ"
  }
};
